<template>
  <section id="spacer"></section>
</template>

<script>
export default {
    name: 'Spacer'
}
</script>

<style>
#spacer{
    height: 20vh;
}
</style>