<template>
  <section class="container my-4">
    <YearInfo year="2019" coach="Cary Lemasters" season="1-13-1" playoffs="0-1" gf="18" ga="89" wp="0.094"/>
    <hr>
    <section class="py-2">
        <table class="table table-striped">
        <thead>
            <tr>
            <th scope="col">Date</th>
            <th scope="col">Opponent</th>
            <th scope="col">Location</th>
            <th scope="col">Result</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <th scope="row">08/26</th>
                <td>East Palestine</td>
                <td>Away</td>
                <td>0-5 Loss</td>
            </tr>
            <tr>
                <th scope="row">09/04</th>
                <td>United</td>
                <td>Away</td>
                <td>1-10 Loss</td>
            </tr>
            <tr>
                <th scope="row">09/07</th>
                <td>Heartland Christian</td>
                <td>Away</td>
                <td>0-4 Loss</td>
            </tr>
            <tr>
                <th scope="row">09/07</th>
                <td>Columbiana</td>
                <td>Away</td>
                <td>0-8 Loss</td>
            </tr>
            <tr>
                <th scope="row">09/11</th>
                <td>Leetonia</td>
                <td>Home</td>
                <td>2-2 Tie</td>
            </tr>
            <tr>
                <th scope="row">09/14</th>
                <td>Jackson Milton</td>
                <td>Home</td>
                <td>1-2 Loss</td>
            </tr>
            <tr>
                <th scope="row">09/17</th>
                <td>Oak Glen</td>
                <td>Home</td>
                <td>0-5 Loss</td>
            </tr>
            <tr>
                <th scope="row">09/19</th>
                <td>Jefferson County Christian</td>
                <td>Home</td>
                <td>1-4 Loss</td>
            </tr>
            <tr>
                <th scope="row">09/23</th>
                <td>East Palestine</td>
                <td>Home</td>
                <td>0-7 Loss</td>
            </tr>
            <tr>
                <th scope="row">09/25</th>
                <td>Heartland Christian</td>
                <td>Home</td>
                <td>0-5 Loss</td>
            </tr>
            <tr>
                <th scope="row">09/28</th>
                <td>Edison</td>
                <td>Away</td>
                <td>4-6 Loss</td>
            </tr>
            <tr>
                <th scope="row">09/30</th>
                <td>United</td>
                <td>Home</td>
                <td>0-8 Loss</td>
            </tr>
            <tr>
                <th scope="row">10/02</th>
                <td>Columbiana</td>
                <td>Home</td>
                <td>3-10 Loss</td>
            </tr>
            <tr>
                <th scope="row">10/07</th>
                <td>Leetonia</td>
                <td>Away</td>
                <td>6-5 Win</td>
            </tr>
            <tr>
                <th scope="row">10/07</th>
                <td>Jackson Milton</td>
                <td>Away</td>
                <td>0-2 Loss</td>
            </tr>
            <tr>
                <th scope="row">PLAYOFFS</th>
            </tr>
            <tr>
                <th scope="row">10/15</th>
                <td>Heartland Christian</td>
                <td>Away</td>
                <td>0-6 Loss</td>
            </tr>
        </tbody>
        </table>
    </section>

    <hr>

    <section class="py-2">
        <h2>Stat Leaders: </h2>
        <table class="table table-striped">
        <thead>
            <tr>
                <th scope="col">Player</th>
                <!-- <th scope="col">Grade</th> -->
                <th scope="col">Goals</th>
                <th scope="col">Assists</th>
                <th scope="col">Saves</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <th scope="row">Casey Lemasters</th>
                <!-- <th>Junior</th> -->
                <td>13</td>
                <td>2</td>
                <td>0</td>
            </tr>
            <tr>
                <th scope="row">Jonathan Walker</th>
                <!-- <th>Senior</th>    -->
                <td>2</td>
                <td>1</td>
                <td>0</td>
            </tr>
            <tr>
                <th scope="row">Darius Stanley</th>
                <!-- <th>Freshman</th> -->
                <td>1</td>
                <td>2</td>
                <td>0</td>
            </tr>
            <tr>
                <th scope="row">Leah Vinton</th>
                <!-- <th>Junior</th>        -->
                <td>1</td>
                <td>2</td>
                <td>0</td>
            </tr>
            <tr>
                <th scope="row">CJ Heffner</th>
                <!-- <th>Senior</th>   -->
                <td>1</td>
                <td>0</td>
                <td>0</td>
            </tr>
            <tr>
                <th scope="row">Ashley Heffner</th>
                <!-- <th>Freshman</th>          -->
                <td>0</td>
                <td>1</td>
                <td>0</td>
            </tr>
            <tr>
                <th scope="row">Levi Boley</th>
                <!-- <th>Senior</th> -->
                <td>0</td>
                <td>0</td>
                <td>184</td>
            </tr>
        </tbody>
        </table>
    </section>

    <Spacer />
  </section>
</template>

<script>
import YearInfo from '@/components/Year-Info.vue';
import Spacer from '@/components/Spacer.vue';

export default {
    name: 'Home',
    components: {
        YearInfo,
        Spacer
    },
    metaInfo: {
    title: "2019 Schedule | Wellsville Soccer - WHS Tiger Athletics in Wellsville, Ohio",
    meta: [
      { name: "author", content: "Github: @TheKicker" },
      {
        name: "description",
        content:
          "The schedule, stat leaders, results, and other information about the 2019 WHS Wellsville Tiger Soccer season.",
      },
      {
        name: "keywords",
        content:
          "Wellsville, Ohio, WHS, Wellsville Tigers, OH, High School, HS, Tigers, Athletics, Soccer, Futbol, Football, Boys, Girls, Co-ed, OHSAA, OVAC, EOAC",
      },
      // OpenGraph data (Most widely used)
      { property: "og:title", content: "2019 Schedule | Wellsville Soccer - WHS Tiger Athletics in Wellsville, Ohio" },
      { property: "og:site_name", content: "2019 Schedule | Wellsville Soccer - WHS Tiger Athletics in Wellsville, Ohio" },
      // The list of types is available here: http://ogp.me/#types
      { property: "og:type", content: "website" },
      // Should the the same as your canonical link, see below.
      {
        property: "og:url",
        content: "https://wellsvillesoccer.com/results/2019-season",
      },
      {
        property: "og:image",
        content: "https://wellsvillesoccer.com/wellsville-soccer-ogp.png",
      },
      // Often the same as your meta description, but not always.
      {
        property: "og:description",
        content:
          "The schedule, stat leaders, results, and other information about the 2019 WHS Wellsville Tiger Soccer season.",
      },
      // Twitter card
      { name: "twitter:card", content: "summary" },
      {
        name: "twitter:site",
        content: "https://wellsvillesoccer.com/results/2019-season",
      },
      { name: "twitter:title", content: "2019 Schedule | Wellsville Soccer - WHS Tiger Athletics in Wellsville, Ohio" },
      {
        name: "twitter:description",
        content:
          "The schedule, stat leaders, results, and other information about the 2019 WHS Wellsville Tiger Soccer season.",
      },
      // Your twitter handle, if you have one.
      { name: "twitter:creator", content: "Github: @TheKicker" },
      {
        name: "twitter:image:src",
        content: "https://wellsvillesoccer.com/wellsville-soccer-ogp.png",
      },
      // Google / Schema.org markup:
      { itemprop: "name", content: "2019 Schedule | Wellsville Soccer - WHS Tiger Athletics in Wellsville, Ohio" },
      {
        itemprop: "description",
        content:
          "The schedule, stat leaders, results, and other information about the 2019 WHS Wellsville Tiger Soccer season.",
      },
      {
        itemprop: "image",
        content: "https://wellsvillesoccer.com/wellsville-soccer-ogp.png",
      },
    ],
  }
}
</script>

<style>

</style>