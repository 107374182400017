<template>
  <section class="component">
    <h1>{{ year }} Season </h1>
    <div class="parent">
        <div class="left">
            <p class="fs-4">Head Coach: {{ coach }} <br> Regular Season: {{ season }} <br>  Playoffs: {{ playoffs }}</p>
        </div>
        <div class="right">
            <p class="fs-4"><span>Goals For:</span> {{ gf }} <br> Goals Against: {{ ga }} <br> Win %: {{ wp }}</p>
        </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'YearInfo',
  props: {
    year: String,
    coach: String,
    season: String,
    playoffs: String,
    gf: String,
    ga: String,
    wp: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.parent{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.left{
    display: flex;
    flex-direction: column;
}
.right{
    display: flex;
    flex-direction: column;
    text-align: right;
}

@media only screen and (max-width: 1000px) {
  .parent{
    flex-direction: column;
  }
  .left, .right{
    text-align: left;
  }
}
</style>
